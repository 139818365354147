body {
  min-height: 100vh;
  position: relative;
  padding-bottom: 6rem;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
}

.container {
  max-width: 1200px !important;
}

.navbar {
  box-shadow: none !important;
}